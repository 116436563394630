@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/loaders.css/src/loaders.scss';

.toast-top-right {
  top: 80px;
  right: 12px;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: inline-block;
}

.navbar-dark {
  padding: 0.1rem 0.5rem 0.1rem 1rem;
  border-bottom: 1px solid $equinor_lightGrey;
  .navbar-nav .nav-link {
    color: black;
    margin-top: 6px;
  }
}

.btn-row > .btn {
  margin-right: 1.5em;
  min-width: 100px;
}

.app-header {
  background-color: $equinor_light_bg;
  .brand {
    white-space: nowrap;
    .nav-item {
      color: black;
      vertical-align: middle;
    }
    .brand-logo {
      margin-left: 12px;
      margin-bottom: -2px;
      max-width: 100px;
      height: auto;
    }
    .divider {
      border-left: 1px solid $equinor_lightGrey;
      width: 1px;
      height: 30px;
      display: inline-block;
      margin-left: $brand-spacing;
      margin-right: $brand-spacing;
    }
    .loader {
      position: relative;
      left: 8px;
      bottom: 2px;
    }
    @include media-breakpoint-down(xs) {
      .brand-logo {
        max-width: 20vw;
      }
      .divider {
        margin-left: $brand-spacing * 0.3;
        margin-right: $brand-spacing * 0.3;
      }
      .app-logo {
        max-width: 40vw;
      }
    }
  }

  .menu-item {
    color: black;
    svg {
      margin-top: -5px;
      margin-right: 6px;
    }
  }

  .menu-item:hover {
    color: black;
  }
}
.people-container {
  .col {
    display: flex;
    flex-direction: row;
  }
  .card {
    display: flex;
    border-color: $equinor_lightGrey;
    flex-direction: column;
    margin: 12px 0;
    box-shadow: 0 8px 8px rgba($equinor_lightGrey, 0.8);
    .card-header {
      background-color: $equinor_lightGrey;
      border-bottom: 1px solid $equinor_lightGrey;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .card-title {
        font-size: 14pt;
        margin-bottom: 0.2em;
        color: black;
      }
      .card-subtitle {
        margin-bottom: 0;
        font-size: 12pt;
        font-weight: 200;
        color: black;
      }
    }
    .card-content {
      display: flex;
      flex-direction: row;
      height: 100%;
      .card-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $equinor_light_bg;
        justify-content: flex-start;
        img {
          @extend .img-fluid;
          min-width: 120px;
          max-width: 120px;
        }
        p {
          color: black;
          font-size: 10pt;
          font-weight: 400;
          margin-top: 4px;
        }
      }
      .card-link {
        position: absolute;
        bottom: 6px;
        right: 12px;
      }
    }
  }
}

input[type='radio'] {
  transform: scale(1);
}

/**
  * Animation/transition styles
  */
.card-enter {
  left: -500px;
  opacity: 0.01;
}

.card-enter.card-enter-active {
  opacity: 1;
  transform: translateX(500px);
  transition: all 300ms ease-in;
}

.card-exit {
  opacity: 1;
}

.card-exit.card-exit-active {
  transform: translateX(-100%);
  opacity: 0.1;
  transition: all 300ms ease-in;
}

.nav-item .dropdown .nav-link .dropdown-toggle .dropdown-menu .dropdown-menu-right .navbar-dark {
  color: black;
}

#userDropdown {
  color: black;
}

.btn-primary {
  background-color: $equinor_primary;
  color: white;
  border-color: $equinor_primary;
}

.btn-primary:hover {
  background-color: $equinor_primary;
  color: white;
  border-color: $equinor_primary;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $equinor_lightGrey;
  color: black;
  border-color: $equinor_primary;
}

.HeatMap {
  background-color: $equinor_light_bg;
  border: 2px solid $equinor_primary;
}

.HeatMapText {
  color: $equinor_secondary;
  font-size: 1.1em;
}



.border-primary {
  border-color: $equinor_primary !important;
}

.list-group-item.active {
  background-color: $equinor_primary;
  border-color: $equinor_primary;
}

.list-group {
  border-color: $equinor_primary;
  border-bottom: 1px solid $equinor_primary;
}



/* FOlder structure */
.menu-structure {

  .folder-item {
    position: relative;
    width: 132px;
    margin-right: 10px;
    cursor: pointer;
  }

  .context-menu {
    position: absolute;
    top: 35px;
    left: 0;
    color: black;
    background: white;
    width: 132px;
    z-index: 10;
    font-size: 12px;
    display: none;
    padding: 6px 0 0;
    &.isVisible {
      display: block;
    }
    &.listItem {
      right: 0;
      left: auto;
    }
    .context-menu-item {
      display: block;
      padding: 6px 0;
      width: 130px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .sub-menu-item {
    font-size: 14px;
    color: $equinor_light_bg;
    margin-right: 10px;
    &.isVisible {
      display: block;
    }
  }

}


.folder-modal-content {
  position: relative;
  .folder-search {
    position: absolute;
    right: 12px;
    top: 6px;
  }
}

/* printable report */
@media print {
  @page {
    size: A4;
    margin: 2cm
  }
  hr {
    page-break-after: always;
  }
  @page:last {
    @bottom-center {
        content: "End of Report.";
    }
  }
  
}


