/* Variables */

// colors
$statoil_pink: #FE30A1;
$statoil_dark_bg: #323232;
$equinor_light_bg: #FFF;
$statoil_blue: #2299d5;
$statoil_background: #dfe1e3;


$equinor_primary: #007079;
$equinor_secondary: #243746;

$equinor_lightGrey: #F2F2F2;

// bootstrap theming
$body-bg: white;
$primary: $equinor_primary;
$secondary: $equinor_secondary;
$dark: $statoil_background;
$danger: #ff113b;

// loaders
$ball-size: 10px;
$primary-color: $statoil_blue;

// app
$dark-bg: $statoil_dark_bg;
$brand-spacing: 20px;
$font-size-base: 0.8rem;
